<template>
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="end">
        <ion-button @click="closeModal">Cancel</ion-button>
      </ion-buttons>
      <ion-title>{{ title }}</ion-title>

    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-label>
        Username
    </ion-label>

    <ion-input v-model="query" placeholder="Search by username"/>
    <ion-button expand="block" @click="search">Search</ion-button>


    <div v-if="matches!=null">
        <div v-if="matches.length == 0">
          <ion-text class="">
            No matches found!
          </ion-text>
        </div>
        <div v-else>
          <ion-list>
            <ion-item v-for="item in matches" :key="item.PublicKeyBase58Check">
              <ion-avatar slot="start">
                <img :title="item.Username" :src="utils.publicKeyImageUrl(item.PublicKeyBase58Check)">
              </ion-avatar>
              <ion-text>{{ item.Username }}</ion-text>
              <div v-if="accountAlreadyAdded(item.PublicKeyBase58Check)" class="ml-auto">
                -
              </div>
              <ion-button v-else slot="end" @click="submitModal(item)">
                Add account
              </ion-button>
            </ion-item>
          </ion-list>
        </div>
    </div>

  </ion-content>
</template>

<script>
import {
    modalController,

    IonAvatar,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonInput,
    IonLabel,
    IonTitle,
    IonToolbar,

    IonList,
    IonItem,
    IonText,

} from "@ionic/vue";
import { ref, defineComponent } from "vue";

import { ApiService } from "../services/api";
import { Utils } from "../services/utils";

export default defineComponent({

  props: {
    title: { type: String, default: "Super Modal" },
  },

  setup(props) {
    const query = ref(null);
    const selectedUser = ref(null);
    const matches = ref(null);
    const utils = new Utils;

    const closeModal = async () => {
        await modalController.dismiss(null, 'cancel');
    };

    const submitModal = async (item) => {
        const params = {
          username: item.Username,
          publicKey: item.PublicKeyBase58Check,
          type: 'deso'
        };
        await modalController.dismiss(params, 'submit');
    };

    const search = async () => {
        const response = await ApiService.getProfiles(query.value);

        // pluck the publicKeys
        // send to license check
        // update the state 

        matches.value = response.data;
    };

    const accountAlreadyAdded = (publicKey) => {
      if (props.accounts) {
        for(const item of props.accounts) {
          if (item.publicKey == publicKey) {
            return true;
          }
        }
      }

      return false;
    };

    return {
        utils,
        query,
        matches,
        selectedUser,

        accountAlreadyAdded,

        closeModal,
        submitModal,

        search,
    };
  },

  components: { 
    IonAvatar,
    IonButton, IonButtons, IonContent, IonHeader, IonInput, IonLabel, IonTitle, IonToolbar,
    IonList,
    IonItem,
    IonText,    
  },
});
</script>
